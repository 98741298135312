import React, { useRef, useState, useEffect, useCallback } from "react"
import classNames from "classnames"
import { SECTION_NAMES } from "common/sectionNames"
import { ReactComponent as Logo } from "images/Home_logo.svg"
import { ReactComponent as PlusIcon } from "images/Menuopener.svg"

import NavLink from "./NavLink"

import styles from "./NavControl.module.scss"

export default ({ isDisabled }) => {
  const menuContainerRef = useRef()
  const [isExpanded, setIsExpanded] = useState()

  const handleScroll = useCallback(() => {
    if (isExpanded) {
      setIsExpanded(false)
    }
  }, [isExpanded, setIsExpanded])

  const handleClick = useCallback(
    (event) => {
      console.log(menuContainerRef.current, event.target)
      if (!menuContainerRef.current.contains(event.target)) {
        setIsExpanded(false)
      }
    },
    [isExpanded, setIsExpanded]
  )

  useEffect(() => {
    if (isExpanded) {
      window.addEventListener("wheel", handleScroll)
      window.addEventListener("touchmove", handleScroll)
      window.addEventListener("click", handleClick)
    }

    return () => {
      window.removeEventListener("wheel", handleScroll)
      window.removeEventListener("touchmove", handleScroll)
      window.removeEventListener("click", handleClick)
    }
  }, [isExpanded])

  return (
    <>
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        className={classNames(styles.menuOpenButton, {
          [styles.expanded]: isExpanded,
        })}
      >
        <PlusIcon />
      </div>
      <div
        ref={menuContainerRef}
        className={classNames(styles.navControl, {
          [styles.expanded]: isExpanded,
        })}
      >
        <NavLink
          className={styles.navMenuItem}
          path={SECTION_NAMES.HOME}
          isDisabled={isDisabled}
        >
          <Logo />
        </NavLink>
        <NavLink
          className={styles.navMenuItem}
          path={SECTION_NAMES.ARTISTS}
          isDisabled={isDisabled}
        >
          <span>KünstlerInnen</span>
        </NavLink>
        <NavLink
          className={styles.navMenuItem}
          path={SECTION_NAMES.PROGRAM}
          isDisabled={isDisabled}
        >
          <span>Programme</span>
        </NavLink>
        <NavLink
          className={styles.navMenuItem}
          path={SECTION_NAMES.TICKETS}
          isDisabled={isDisabled}
        >
          <span>Tickets</span>
        </NavLink>
        <NavLink
          className={styles.navMenuItem}
          path={SECTION_NAMES.PARTNERS}
          isDisabled={isDisabled}
        >
          <span>Partner &amp; wir</span>
        </NavLink>
      </div>
    </>
  )
}
