import React from "react"
import classNames from "classnames"

import { SECTION_NAMES } from "common/sectionNames"

import styles from "./ProgramSection.module.scss"
import commonStyles from "../../common/commonStyles.module.scss"

export default ({ onRef }) => (
  <div
    id={SECTION_NAMES.PROGRAM}
    ref={onRef}
    className={classNames(commonStyles.sectionWrapper, styles.sectionWrapper)}
  >
    <h2 className={classNames(commonStyles.title, styles.title)}>
      Jedes Programm eine eigene Welt
    </h2>
    <div className={styles.content}>
      <div className={styles.programs}>
        {/* Program 1 */}
        <p className={styles.date}>Samstag, 15. Juni 2024</p>
        <div>
          18 Uhr Bernardisaal, Stift Stams.
          <br />
          <br />
          (Ab 17:15 laden wir Sie auf einen Apero zur Einstimmung.)
        </div>
        <br />
        <div>
          <div>
            <span className={styles.composer}>C&eacute;sar Franck</span> –
            Sonate in A-Dur f&uuml;r Violine und Klavier
          </div>
          <div>
            <span className={styles.composer}>Eug&egrave;ne Bozza</span> –
            Fantaisie f&uuml;r Fagott und Klavier
          </div>
          <div>
            <span className={styles.composer}>Domenico Scarlatti</span> –
            Klaviersonate in C-Dur K&thinsp;49
          </div>
          <div>
            <span className={styles.composer}>Astor Piazzolla</span> –
            &bdquo;Las cuatro estaciones porte&ntilde;as&rdquo; f&uuml;r
            Violine, Fagott und Klavier&ensp;(arr. Jos&eacute; Bragato)
          </div>
          <div className={styles.programFooter}>
            Es spielen Mariya Nesterovska,{" "}
            <nobr>Hubert Mittermayer Nesterovskiy, </nobr>
            <nobr>Nenad Le&#269;i&#263;.</nobr>
          </div>
          {/* Program 2 */}
          <p className={styles.date}>Samstag, 29. Juni 2024</p>
          <div>
            18 Uhr Bernardisaal, Stift Stams.
            <br />
            <br />
            (Ab 17:15 laden wir Sie auf einen Apero zur Einstimmung.)
          </div>
          <br />
          <div>
            <span className={styles.composer}>Arcangelo Califano</span> –
            Triosonate in F-Dur
          </div>
          <div>
            <span className={styles.composer}>Domenico Scarlatti</span> – Zwei
            Sonaten f&uuml;r Cembalo solo{" "}
            <nobr>(K&thinsp;61 und K&thinsp;33)</nobr>
          </div>
          <div>
            <span className={styles.composer}>Johann Ernst Galliard</span> –
            Sonate Nr. 6 in C-Dur f&uuml;r Fagott und Basso continuo
          </div>
          <div>
            <span className={styles.composer}>Tarquinio Merula</span> – Ruggiero
          </div>
          <div>
            <span className={styles.composer}>Nicola Matteis</span> – Fantasia
            f&uuml;r Violine solo
          </div>
          <div>
            <span className={styles.composer}>
              Heinrich I.&thinsp;F.&thinsp;v.&thinsp;Biber
            </span>{" "}
            – Sonate Nr.&thinsp;8 f&uuml;r Violine und{" "}
            <nobr>Basso continuo</nobr>
          </div>
          <div>
            <span className={styles.composer}>Johann Ernst Galliard</span> –
            Sonate Nr.&thinsp;4 in e-moll f&uuml;r Fagott und Basso continuo
          </div>
          <div>
            <span className={styles.composer}>Johann Philipp Krieger</span> –
            Triosonate Nr.&thinsp;5
          </div>
          <div>
            <span className={styles.composer}>Andrea Falconieri</span> –
            Ciaccona
          </div>
          <br />
        </div>
        <div className={styles.programFooter}>
          Es spielen Mariya Nesterovska, Hubert Mittermayer Nesterovskiy,
          Fran&ccedil;ois Guerrier, Rafael Bonavita und{" "}
          <nobr>Jan Krigovsky</nobr> auf historischen Instrumenten.
        </div>
        {/* Datenschutz */}
        <p className={styles.dataprotectionText}>
        Hinweis: Bei unseren Veranstaltungen werden Foto-, Video- und Tonaufnahmen
        gemacht. Mit Buchung Ihres Tickets erkl&auml;ren Sie sich mit 
        unseren <a href="https://www.obertone.at/datenschutz.pdf">Datenschutzhinweisen</a> einverstanden.            
          </p>
      </div>
    </div>
  </div>
)
