import React, { useState, useMemo, useRef, useEffect } from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"

import { SECTION_NAMES } from "common/sectionNames"

import styles from "./HomeSection.module.scss"
import commonStyles from "../../common/commonStyles.module.scss"
import parallaxStyles from "../../common/parallaxStyles.module.scss"

export default ({ onRef }) => {
  const resizeTimeout = useRef()
  const contentRef = useRef()
  const contentPart2Ref = useRef()
  const [contentHeight, setContentHeight] = useState()

  const pageStyle = useMemo(
    () =>
      contentHeight && contentHeight > window.innerHeight
        ? { height: contentHeight }
        : {},
    [contentHeight]
  )

  const handleWindowResize = () =>
    setContentHeight(
      contentRef.current && contentRef.current.getBoundingClientRect().height
    )

  useEffect(() => {
    resizeTimeout.current = setInterval(handleWindowResize, 250)

    return () => clearTimeout(resizeTimeout.current)
  }, [])

  return (
    <div
      id={SECTION_NAMES.HOME}
      ref={onRef}
      className={classNames(
        parallaxStyles.parallaxGroup,
        commonStyles.sectionWrapper,
        styles.sectionWrapper
      )}
      style={pageStyle}
    >
      <div
        className={classNames(
          parallaxStyles.parallaxLayer,
          parallaxStyles.parallaxLayerBack,
          styles.sectionBackground
        )}
        style={pageStyle}
      />
      <div
        className={classNames(
          parallaxStyles.parallaxLayer,
          parallaxStyles.parallaxLayerBase,
          styles.contentWrapper
        )}
        style={pageStyle}
      >
        <div className={styles.content} ref={contentRef}>
          <p className={styles.smallText}>15. und 29. Juni 2024</p>
          <h2 className={classNames(commonStyles.title, styles.title)}>
            Der Sommer
            <br />
            kommt &mdash; mit Lust
            <br />
            auf Musik!
          </h2>
          <p className={styles.mediumText}>
            Zuerst drei alte Freunde im Konzert, dann spannende und besondere
            Barockmusik auf Originalinstrumenten.
          </p>

          <a
            href="https://shop.ticketpay.de/organizer/XTVVA6UY?view=grid"
            className={classNames(
              styles.ticketsLink,
              commonStyles.withRoundedBorder
            )}
          >
            Direkt zu den Tickets
          </a>
          <br />
          <br />
          <br />
          <p className={styles.mediumText}>
            Programme und Tickets für die elften OBERT&Ouml;NE Kammermusiktage{" "}
            <br></br>
            finden Sie auf &ensp;
            <a href="http://www.obertone.at/">www.obertone.at</a>
          </p>
        </div>
      </div>
    </div>
  )
}
